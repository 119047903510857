import React, { useRef } from 'react';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import "./contact.css";


const Contact = () => {
  const form = useRef();
  
  const sendEmail = (e) => {

    e.preventDefault();
    // console.log(form.current);

    emailjs
      .sendForm(
        "service_4f0nila",
        "template_cijl2v7",
        form.current,
        "YNBCfU8sZwJ4SvtqD"
      )
      .then((response) => {

        toast( "Message Sent Succesfull!...", {
                position: "top-center",
                type:"success",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        console.log('SUCCESS!', response.status, response.text);
       
      }, (err) => {
        console.log('FAILED...', err);
      });

    e.target.reset();

  }

  return (
    <>
     <ToastContainer />
      <section id="contact" className="contact">
        <h1 className="heading">get in touch</h1>
      </section>

      <div className="contact-in">
        <div className="contact-map">
          {/* <iframe src="https://goo.gl/maps/isKTzv5f6r7qccvPA"   allowfullscreen="lkjlk" loading="lazy" title="ntcs-location" id="iframe-title" /> */}
         
        </div>

        <div className="contact-form">
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="user_name" placeholder="Name" className="contact-form-txt" required />
            <input type="tel" id="phone" name="mobile" pattern="[0-9]{10}" required placeholder="Contact number" className="contact-form-phone" />
            <input type="email" name="user_email" placeholder="Email" className="contact-form-email" required />
            <textarea placeholder="Your Message" name="message" className="contact-form-txtarea" required ></textarea>
            <button type="submit" className="contact-form-btn">Send</button>
          </form>

        </div>
      </div>

    </>
  )
}

export default Contact