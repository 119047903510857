import React from 'react'
import "./companyabout.css";
import { Link } from 'react-router-dom';
const CompanyAbout = () => {
  return (
    <>
      <section id="about" className="about">
        <h1 className="heading"><strong>Welcome To The</strong></h1>
        <div className="row">
          <div className="content">
            <h1>Next Tech Consultancy Services !</h1>
            <p>ntcs company is allocated in Patna,Bihar Founded in 2023
              with a goal to change the bussiness strategy
              and provides IT solutions for Business/StartUps/Organisations
              and helps them to <b>turn business online </b><br />
              and to cover large area of the market ,also made easy for them
              <span> </span> <b>markenting and management work. </b> </p>
            <Link to="/companyDetails"><button className="btn-more ">Show More...</button></Link>
            
          </div>
        </div>
      </section>
      <br/>
    </>

  )
}

export default CompanyAbout;