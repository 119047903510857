import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import "./requirement.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const Requirement = () => {
    const userData=useSelector((state)=>state.currentUser)||[];
    // console.log(userData[0]?.id)
    const navigate = useNavigate();
    const [err, setErr] = useState();
    const [reqData, setReqData] = useState({
        name: '',
        email: '',
        phone: '',
        whatsapp: '',
        portfolio: '',
        project_type: '',
        msg: '',
        user_order_id:`${userData[0]?.id}`

    });


    // console.log(userId);

    const handleChange = (e) => {
        setReqData({ ...reqData, [e.target.name]: e.target.value });

    }
    // console.log(reqData);

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const reqUser = await axios.post("https://next-tech-backend.onrender.com/api/project-requirements",
                { "data": reqData },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
                    }
                });
            if (reqUser) {
                navigate(`/client/${userData[0]?.id}`);
            }else{
                navigate("/requirement");
            }
            // console.log(reqUser);
            // navigate(`/client/${userId}`);
        } catch (error) {
            setErr(error.response.data.error.message);
            console.log(error);
            navigate("/requirement");
        }

    }
    return (
        <>
            <form className="form-style-9">
                <div>
                    <h3>Project Details</h3>
                    <p className='text-danger'>* indicates required</p>
                </div>
                <hr />
                <ul>
                    <li>
                        <input type="text" name="name"
                            onChange={handleChange} className="field-style field-split align-left" required placeholder="Your Name*" />
                        <input type="email" name="email"
                            onChange={handleChange} className="field-style field-split align-right" required placeholder="Email*" />

                    </li>
                    <li>
                        <input type="text" name="phone"
                            onChange={handleChange} className="field-style field-split align-left" required placeholder="Phone*" />
                        <input type="text" name="whatsapp"
                            onChange={handleChange} className="field-style field-split align-right" required placeholder="Whatsapp No.*" />
                    </li>
                    <li>
                        {/* <input type="date" name="dob"
                            onChange={handleChange} className="field-style field-split align-left" required placeholder="Date of Birth*" /> */}
                        {/* <input type="url" 
                            onChange={handleChange} className="field-style field-split align-right" placeholder="Portfolio Link" /> */}
                    </li>

                    <h5>Do you currently have a website?</h5>
                    <li>
                        <input type="url"  name="portfolio" onChange={handleChange} className="field-style field-full align-none" placeholder="Portfolio Link/Website Link" />
                    </li>
                    {/* <div className='web-purpose'>
                        <h5>What is the website’s main purpose?</h5>
                        <input className="form-check-input" type="checkbox" value='' id="flexCheckDefault" />
                        <label className="form-check-label" for="flexCheckDefault">
                            to sell products?
                        </label>
                        <input className="form-check-input"  name="username"
                        onChange={handleChange} type="checkbox" value='' id="flexCheckChecked"
                       
                        />
                        <label className="form-check-label" for="flexCheckChecked">
                            to promote services?
                        </label>
                        <input className="form-check-input"  name="username"
                        onChange={handleChange} type="checkbox" value='' id="flexCheckChecked"
                        // checked 
                        />
                        <label className="form-check-label" for="flexCheckChecked">
                            to collect email addresses?
                        </label>
                        <input className="form-check-input"  name="username"
                        onChange={handleChange} type="checkbox" value='' id="flexCheckChecked"
                        // checked 
                        />
                        <label className="form-check-label" for="flexCheckChecked">
                            to build a brand / community?
                        </label>
                    </div> */}


                    <div className="box">
                        <h5 className='mt-2'>Chooser Your Application Type</h5>
                        <select name="project_type"
                            onChange={handleChange} required>
                            <option disabled>Select Project Type </option>
                            <option>ERP Services</option>
                            <option>Web App Development</option>
                            <option>Mobile App Development</option>
                            <option>Graphics Desing</option>
                            <option>Logo Design</option>
                            <option>Static Web Page</option>
                            <option disabled>Socail Media </option>

                        </select>
                    </div>



                    <li>
                        <textarea name="msg"
                            onChange={handleChange} className="field-style" placeholder="Message*" required></textarea>
                    </li>

                    <li>
                        <button
                            //  disabled={loading} 
                            onClick={handleClick}
                            className="btn btn-info text-white fw-5">
                            Submit Order
                        </button>
                    </li>
                    {err ? <span style={{ color: "red" }}>{err}..!</span> : <span></span>}
                </ul>
            </form>

        </>
    )
}

export default Requirement;