import React from 'react';
import "./navbar.css";
import { Link } from 'react-router-dom';
const Navbar = () => {

    const alertMsg = () => {
        alert("Not Available !...");
     
    }


    return (
        <div className="navbar">

            <div className="navbar-container container">

                <input type="checkbox" className='chk-box' />
                <div className="hamburger-lines">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
                <ul className="menu-items">
                    <li><Link to="/"><i className='fas fa-home'></i>Home</Link></li>
                    {/* <li><a  href="/services"><i className='fas fa-group'></i>service</a></li> */}
                    <li onClick={alertMsg}><a href="/"><i className='fas fa-child'></i>Student Panel</a></li>

               
                    <li><a href="/contact"><i className='fas fa-headset'></i>Contact Us</a></li>
                    <li onClick={alertMsg}><a href="/"><i className='fas fa-podcast'></i>Job Portals</a></li>
                    <div className="dropdown" >
                 <button className="dropbtn pl-5 pr-5" style={{backgroundColor:"white",color:"#02c8eb",border:"0.5px solid #e2dcdc",marginTop:"-5px"}} >Company</button>
                    <div className="dropdown-content">
                    <a href="/companyDetails" > About</a>
                    <a href="/"> Why Choose Us</a>
                   
                    </div>
                 </div>
                </ul>

            </div>
        </div>
    )
}

export default Navbar;