import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import "./header.css";
import Navbar from '../Navbar/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/Reducers/authReducer';
const Header = () => {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.currentUser) || [];
  // console.log(loginData[0]);
  // const data = JSON.parse(localStorage.getItem("userData"));
  const logout=()=>{
    // localStorage.removeItem('userData');
    // navigate("/login");
    dispatch(actions.logoutUser());
    navigate("/login");
  }

       
  return (
    <>
      <div className="topbar" >
        <div className="topContainer">
          <Link to="/" > <img src="/images/ntcs-logo1.jpeg" style={{ height:"70px",width:"100px" }} alt="ntcs-logo" className='img-fluid mt-3'/></Link>

          <div className='log-reg-btn'>
          {loginData[0]?.id? <Link to="/login"><button className="topButton" onClick={logout}>Logout</button></Link>: <Link to="/login"><button className="topButton">Login</button></Link>}
           
         {loginData?.length===0?<span></span>:
         
         <div className="dropdown">
         
              <button className="dropbtn p-1">ClientPannel</button>
              <div className="dropdown-content">
              <Link to="/requirement" >Book Service</Link> 
              <Link to={`/client/${loginData[0]?.id}`}> Your Service</Link>

              </div>
              {/* <i className='fas fa-2x fa-bell ml-2 text-dark'>
              <span className="bg-danger "> <sup className='text-white pb-2 notice-btn-up'>1</sup></span>
           
           </i> */}
           
            </div>
            
            }
            {loginData?.length===0?<span></span>:
            <button className="cart-btn"  data-toggle="modal" data-target="#exampleModal">
           <i className="fas fa-2x fa-bell ml-2 text-dark cart-val-btn" ></i> 
          <span className="cart-val">0</span>
           </button>}
           
          </div>

            

        </div>
   <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Ntcs Notifications.</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <p>Opps ! no Notifications yet.</p>
      
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-info" data-dismiss="modal">OK</button>
       
      </div>
    </div>
  </div>
</div>
      </div>
      <Navbar />

    </>
  )
}

export default Header;