import React from "react";
import "./slider.css";
const Slider = () => {
  return (
    <>
      <div>
        <section id="home" className="home">
          <h1> Best Website , Software Development Company in Patna,Bihar. </h1>
          <h2> Bring Your Business Online With ntcs. </h2> <div className="wave wave1"> </div>
          <div className="wave wave2"> </div> <div className="wave wave3"> </div>
        </section>
      </div>
    </>
  );
};

export default Slider;
