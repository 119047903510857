import React from 'react'
import "./service.css";
import { Link } from 'react-router-dom';
import MoreServices from '../MoreService/MoreServices';
import useFetch from '../../hooks/useFetch';

// import axios from 'axios';
const Services = () => {
  const { data, error } = useFetch('/services?populate=*');
  //   const [data, setData] = useState(null);
  //   const [loading, setLoading] = useState(false);
  //   const [error, setError] = useState(null);


  // useEffect(() => {

  //       const fetchData = async() => {
  //           try {
  //               setLoading(true);
  //               const res = await axios.get(process.env.REACT_APP_BASE_API + '/services?populate=*', {
  //                   headers: {
  //                       'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
  //                   }
  //               });
  //               setData(res.data.data);
  //               // console.log(data);
  //           } catch (error) {
  //               setError(true);
  //               console.log(error);
  //           }
  //           setLoading(false);
  //       };
  //       fetchData();
  //   }, []);


  
  return (
    <>
      <div className="pt-5 pb-5" style={{ backgroundColor: "#f2f2f2"}} id="service">
        <div className="container">
          <div className="row">

            <div className="section-head col-sm-12" id="service">
              <h1>Our Services</h1>
              {/* <p>We help you to build high-quality digital solutions and products as well as deliver a wide range of related professional services. We are providing world-className service to our clients.
            </p> */}
            </div>
            {/* {loading && <Loader />} */}
          
            {error?<h2>something Went Wrong...</h2>:data ? data?.map((item) => <ServiceCard item={item} key={item.id} />
            ) : (
            <div />
          )}

            {/* 
            <div className="col-lg-4 col-sm-6">
              <Link to="/service/123" className='details-link'>
                <div className="item"> <span className="icon feature_box_col_one"><i className="fas fa-laptop"></i></span>
                  <h6>Web App Development</h6>
                  <p>Our Custom Web Development Services Include Both Front-End And Back-End Development. Whether It Is Enhancing An Existing App Or Architecting An Enterprise App, Our Developers Are Up For The Challenge.</p>
                </div>
              </Link>
            </div> */}


            {/* <div className="col-lg-4 col-sm-6">
              <Link to="/service/123" className='details-link'>
                <div className="item"> <span className="icon feature_box_col_two"><i className="fas fa-mobile"></i></span>
                  <h6>Mobile App Development</h6>
                  <p>We Have Expertise In Creating Multi-Platform Mobile App Solutions For Both Android And IOS Devices. Using PhoneGap, Xamarin, And React Native,
                    We Offer Custom Mobile App That Runs Smoothly On Multiple Platforms.</p>
                </div>
              </Link>
            </div> */}


            {/* <div className="col-lg-4 col-sm-6">
              <Link to="/service/123" className='details-link'>
                <div className="item"> <span className="icon feature_box_col_three"><i className="fas fa-magic"></i></span>
                  <h6>Graphics Design</h6>
                  <p>Next Tech IT Solutions Graphics Design Team Creates Stunnig Visuals To Elevate Your Brand & Captivate Your Audience. From Logos To Info Graphics, To Web Design,
                    We Offer Wide Range of Customized Solutions.</p>
                </div>
              </Link>
            </div> */}

            {/* <div className="col-lg-4 col-sm-6">
              <Link to="/service/123" className='details-link'>
                <div className="item"> <span className="icon feature_box_col_three"><i className="fas fa-group"></i></span>
                  <h6>IT Counsultancy</h6>
                  <p>Next Tech IT Solutions Has a team of Best Strategic Consultants.
                    Who Will Transform Your Brand And Help You To Improve Your Business Reachability Through Our Vast Experience in
                    Strategy,Positioning & Market Research. </p>
                </div>
              </Link>
            </div> */}


          </div>

        </div>
      </div>

      <MoreServices />

      {/* <div className='more-service'>
        <div className='more-service-text'>
          <h3>More Services...</h3>
        </div>

        <Marquee>
       
        {error ? "Something Went Wrong!" 
            : data?.map((item) => <MarImg item={item} key={item.id} />
            )}
        </Marquee>
      </div> */}

    </>
  )

}


const ServiceCard=({item})=>{
  return(<>
      <div className="col-lg-4 col-sm-6">
              <Link to={`service/${item.id}`} className='details-link'>
                <div className="item"> <span className="icon feature_box_col_one"><i className={item?.attributes.font_icon}></i></span>
                  <h6>{item?.attributes.title}</h6>
                  <p>{item?.attributes?.desc}</p>
                  <Link  to={`service/${item.id}`}> <button className='details-btn p-2'>View Details</button></Link>
                 
                </div>
              
              </Link>
            </div>
  </>)
}
export default Services;
