import React from 'react'
import "./loader.css";
const Loader = () => {
    return (
        <>
<div className='loader-div'>
            <div className='loader-text-div'>
              <h3 className="ldr-animate-charcter">N<span style={{ fontSize: "100px" }}>tcs</span></h3>
              <h2>Next Tech Consultancy Services</h2>
              <br/>
              <div className='loader-main'>
                <div className="lds-facebook">
                    <div>
                    </div>
                    <div>

                    </div>
                    <div>

                    </div>
                  
                </div>
                <h5>Loading...</h5>
            </div>
            </div>
          

</div>
       
        </>
    )
}

export default Loader;