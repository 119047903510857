import React from 'react'
import { Link } from 'react-router-dom';

const Error = () => {
  return (
   <>      
            <div className='ntcs-text-div'>
            <div className='Error-text'>
                <h1>Oops!</h1>
                <p>Page Not Found...</p>
                <Link to="/"><button className='details-btn'>Back To Home</button></Link>
                
            </div>
              <h3 className="animate-charcter">N<span style={{ fontSize: "100px" }}>tcs</span></h3>
              <h1>Next Tech Consultancy Services</h1>
            </div>
            <br></br>
   </>
  )
}

export default Error;