import React from 'react'
import "./feedback.css";
import Marquee from "react-fast-marquee";
const Feedback = () => {
  return (
    <>
      {/* <div className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="section-head-1 col-sm-12" style={{ textAlign: "center;" }}>
              <h4 style={{ marginTop: "-5rem" }} id="portfolio"><span>Our</span> Projects</h4>

              <p>The objective of IT Company is to enable a large number of youth to take up <br />industry-relevant skill training that will help them<br /> in securing a better livelihood.
              </p>
            </div>

            <div className="col-lg-4 col-sm-6">

              <div className="item"> <span className="icon feature_box_col_four"><i className="fa fa-database"></i></span>
                <h6>Project-1</h6>

                <p>Your Vision,Your Software | We Just Built It For Your High Business Efficiency.<br /><br /><br /></p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="item"> <span className="icon feature_box_col_five"><i className="fa fa-upload"></i></span>
                <h6>Project-2</h6>
                <p>Your Vision,Your Software | We Just Built It For Your High Business Efficiency<br /><br /><br /></p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="item"> <span className="icon feature_box_col_six"><i className="fa fa-camera"></i></span>
                <h6>Project-3</h6>
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor Aenean massa.<br /><br /><br /></p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="communicate">
        <h1>Your Vision,Your Software . </h1>
        <h3>We Just Built It For Your High Business Efficiency.</h3>
        <a href="#contact"><button className="btn">Contact Now</button></a>
      </div>

 */}
<div className='feedback-div'>
<div className="feedback-text">
  <h3>Our Feedback</h3>
</div>
<Marquee>
<figure className="snip1139">
  <blockquote>That's the problem with nature, something's always stinging you or oozing mucous all over you. Let's go and watch TV.
    <div className="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample17.jpg" alt="sample17"/>
  <div className="author">
    <h5>Eleanor Faint<span>
      <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    </span></h5>
  </div>
</figure>
 <figure className="snip1139">
  <blockquote>Sometimes I think the surest sign that intelligent life exists elsewhere in the universe is that none of it has tried to contact us.
    <div className="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample3.jpg" alt="sample3"/>
  <div className="author">
    <h5>Pelican Steve <span>
      <i className='fa fa-star text-warning ml-2 '></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    </span></h5>
  </div>
</figure>

<figure className="snip1139">
  <blockquote>That's the problem with nature, something's always stinging you or oozing mucous all over you. Let's go and watch TV.
    <div className="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample17.jpg" alt="sample17"/>
  <div className="author">
    <h5>Eleanor Faint<span>
      <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    </span></h5>
  </div>
</figure>
<figure className="snip1139">
  <blockquote>That's the problem with nature, something's always stinging you or oozing mucous all over you. Let's go and watch TV.
    <div className="arrow"></div>
  </blockquote>
  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample17.jpg" alt="sample17"/>
  <div className="author">
    <h5>Eleanor Faint<span>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    <i className='fa fa-star text-warning ml-2'></i>
    </span></h5>
  </div>
</figure>
 
   </Marquee>
</div>
 







    </>
  )
}

export default Feedback;