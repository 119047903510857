import React from 'react';
import "./status.css";
import useFetch from '../../hooks/useFetch';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
const UserStatus=()=>{
    // const userData = JSON.parse(localStorage.getItem("userData"));
    const userData=useSelector((state)=>state.currentUser)||[];
    // console.log(userData);
    const { data} = useFetch(`/project-requirements?filters[user_order_id][$eq]=${userData[0]?.id}`);
    // console.log(data);
    return(<>
        <div className="container">
        {data?.length===0 && <div className='no-service-div'>
                 <h3>Opss! You have no any Booked Service...</h3>
               <Link to="/requirement" className="btn btn-warning" data-abc="true"> Book Now</Link>
            </div>}
        {data && data?.map((item) => <CardStatus item={item} key={item.id} />
            )}
        </div>
    </>);
};

const CardStatus=({item})=>{
    const userData = JSON.parse(localStorage.getItem("userData"));
return(<>
     <article className="cards">
        <header className="cards-header"> My Project / Tracking</header>
        <div className="card-body">
            <h6>Order ID:Ntcs{userData?.id}</h6>
            <article className="clientstatus-card">
                <div className="card-body row">
                    <div className="col"> <strong>Started Project time:</strong> <br/>{item?.attributes?.createdAt} </div>
                    <div className="col"> <strong>Developing BY:</strong> <br/> NEXT TECH, | <i className="fa fa-phone"></i> +1598675986 </div>
                    <div className="col"> <strong>Status:</strong> <br/> {item?.attributes?.project_status}</div>
                    <div className="col"> <strong>Tracking #:</strong> <br/> Ntcs{item?.id} </div>
                </div>
            </article>
            <div className="track">
            
                <div className={`${item?.attributes?.project_status==="requirement" ? "step active" : " step inactive"}`}> <span className="icon"> <i className="fa fa-check"></i> </span> <span className="text">Application Requirement</span> </div>
                <div className={`${item?.attributes?.project_status==="inprogress" ? "step active":"step inactive"}`}> <span className="icon"> <i className="fa fa-user"></i> </span> <span className="text"> In Progress</span> </div>
                <div className={`${item?.attributes?.project_status==="testing" ? "step active":"step inactive"}`}> <span className="icon"> <i className="fa fa-truck"></i> </span> <span className="text">Testing </span> </div>
                <div className={`${item?.attributes?.project_status==="deployed" ? "step active":"step inactive"}`}> <span className="icon"> <i className="fa fa-box"></i> </span> <span className="text">Deployed !</span> </div>
            </div>
            <hr/>
         
            <hr/>
            <Link to="/" className="btn btn-warning" data-abc="true"> <i className="fa fa-chevron-left"></i> Back to orders</Link>
           

        </div>
        <h4  > Live Demo</h4>{
            item?.attributes?.website_url? <Link to={ item?.attributes?.website_url} className="btn btn-warning" data-abc="true">  Url-link <i className="fa fa-chevron-right"></i></Link>:<span>You can Visit Your Live Testing Project</span>
            
        }
           <br/>
    </article>
</>)
}
export default UserStatus;
