import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home/Home";
// import Service from "./components/Service/Service";
import About from "./Pages/AboutSingle/About";
import Service from "./Pages/ServiceSingle/Service";
import CompanyDetails from "./Pages/CompanyDtl/CompanyDetails";
import Login from "./Pages/ClientLogin/Login";
import Register from "./Pages/ClientReg/Register";
import Requirement from "./Pages/ClientReuirement/Requirement";
import ClientStatus from "./Pages/ClientStatus/ClientStatus";
import TeamSingle from "./Pages/TeamSingle/TeamSingle";
import Error from "./components/ErrorPage/Error";
import { useSelector } from "react-redux";
import FounderSingle from "./Pages/FounderSingle/FounderSingle";
import ChangePassword from "./Pages/Forgot/ChangePassword";
import NewPassword from "./Pages/Forgot/NewPassword/NewPassword";
import Contact from "./components/ContactUs/Contact";
// import Services from "./components/Services/Services";
import "./App.css";

function App() {
  // const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData);
  const userData=useSelector((state)=>state.currentUser)||[];
  const forgotData=useSelector((state)=>state.forgotUser)||[];
  return (
    <>
      <div className="App">
    
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/requirement" element={userData && userData.length===1?<Requirement />:<Login />} />
            <Route exact path="/client/:id" element={userData && userData.length===1?<ClientStatus />:<Login />} />
            <Route exact path="/companyDetails" element={<CompanyDetails />} />
            <Route exact path="/service/:id" element={<Service />} />
            {/* <Route exact path="/services" element={<Services />} /> */}
            <Route exact path="/contact" element={<Contact />} />

            <Route exact path="/team/:id" element={<TeamSingle />} />
            <Route exact path="/founder/:id" element={<FounderSingle />} />
            <Route exact path="/team/123" element={<About />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/new-password" element={forgotData && forgotData.length===1?<NewPassword />:<ChangePassword />} />
            <Route path="*" element={<Error />} />

          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
