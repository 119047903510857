import React from 'react'
import "./ourteam.css";
// import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useFetch from '../../hooks/useFetch';

const OurTeam = () => {

    const { data, error } = useFetch('/teams?populate=*');

   

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    return (
        <>

            <div className='team-header'>
                <h2>Our Team Members</h2>
            </div>
            <Carousel responsive={responsive}
                swipeable={true}
                draggable={true}
                infinite={true}
                autoPlaySpeed={5000}
                infiniteLoop autoPlay
                className='testimonials'
            >
                
                {error ? <h2>something Went Wrong...</h2>: data ? data.map((item) => <TeamCard item={item} key={item.id} />
                    )
                    :<div/>}

                {/* <div className="testimonial-item row align-items-center">
                    <Link to="/team/123" className='details-link'>
                        <div className="testimonial-img">
                            <img src="/images/testimonial-1.jpg" alt="Testimonial" />
                        </div>
                        <div className="testimonial-text">
                            <h3>Person-1</h3>
                            <h4>Designation</h4>
                            <p> Testimonial </p>
                        </div>
                    </Link>
                </div>

                <div className="testimonial-item row align-items-center">
                    <Link to="/team/123" className='details-link'>
                        <div className="testimonial-img">
                            <img src="/images/testimonial-1.jpg" alt="Testimonial" />
                        </div>
                        <div className="testimonial-text">
                            <h3>Person-1</h3>
                            <h4>Designation</h4>
                            <p> Testimonial </p>
                        </div>
                    </Link>
                </div>
                <div className="testimonial-item row align-items-center">
                    <Link to="/team/123" className='details-link'>
                        <div className="testimonial-img">
                            <img src="/images/testimonial-1.jpg" alt="Testimonial" />
                        </div>
                        <div className="testimonial-text">
                            <h3>Person-1</h3>
                            <h4>Designation</h4>
                            <p> Testimonial </p>
                        </div>
                    </Link>
                </div>
                <div className="testimonial-item row align-items-center">
                    <Link to="/team/123" className='details-link'>
                        <div className="testimonial-img">
                            <img src="/images/testimonial-1.jpg" alt="Testimonial" />
                        </div>
                        <div className="testimonial-text">
                            <h3>Person-1</h3>
                            <h4>Designation</h4>
                            <p> Testimonial</p>
                        </div>
                    </Link>
                </div>
                <div className="testimonial-item row align-items-center">
                    <Link to="/team/123" className='details-link'>
                        <div className="testimonial-img">
                            <img src="/images/testimonial-1.jpg" alt="Testimonial" />
                        </div>
                        <div className="testimonial-text">
                            <h3>Person-1</h3>
                            <h4>Designation</h4>
                            <p> Testimonial</p>
                        </div>
                    </Link>
                </div> */}
                {/* <div className="testimonial-item row align-items-center">
                    <Link to="/team/123" className='details-link'>
                        <div className="testimonial-img">
                            <img src="/images/testimonial-1.jpg" alt="Testimonial" />
                        </div>
                        <div className="testimonial-text">
                            <h3>Person-1</h3>
                            <h4>Designation</h4>
                            <p> Testimonial</p>
                        </div>
                    </Link>
                </div> */}
            </Carousel>
        </>
    )
}




const TeamCard = ({ item }) => {
    return (<>
        <div className="testimonial-item row align-items-center">
            {/* <Link to={`/team/${item.id}`} className='details-link'> */}
                <div className="testimonial-img">
                    <img src={item?.attributes?.img?.data?.attributes?.url}   style={{ width: "100%", height: "100%" }} alt="Testimonial" />
                </div>
                <div className="testimonial-text">
                    <h3>{item?.attributes?.name}</h3>
                    <h4>{item?.attributes?.profession}</h4>
                    <p> {item?.attributes?.bio}</p>
                </div>
            {/* </Link> */}
        </div>
    </>)
}

export default OurTeam;