import React from 'react'
import "./counter.css";

import useFetch from '../../hooks/useFetch';

const Counters = () => {
    const { data, error } = useFetch('/projects?populate=*');
    
    
  return (
   <>
    <section className="counters">
    <div className="container">
    {error && <h2>something Went Wrong...</h2>}
    {data ? data?.map((item) => <CounterCard item={item} key={item.id} />
            ) : (
            <div />
          )}
        {/* <div>
            <i className="far fa-clock fa-4x"></i>
            <div className="counter" data-target="13500">100</div>
            <h3>Working Hours</h3>
        </div>
        <div>
            <i className="fas fa-gift fa-4x"></i>
            <div className="counter" data-target="720">200</div>
            <h3>Completed Projects</h3>
        </div>
        <div>
            <i className="fas fa-users fa-4x"></i>
            <div className="counter" data-target="480">300</div>
            <h3>Happy Clients</h3>
        </div>
        <div>
            <i className="fas fa-award fa-4x"></i>
            <div className="counter" data-target="120">400</div>
            <h3>Awards Received</h3>
        </div> */}
    </div>
</section>

   </>
  )
}


const CounterCard=({item})=>{
    return(<>
        <div>
            <i className={`${item?.attributes.completed_project}`}></i>
            <div className="counter" data-target={`${item?.attributes.datatarget}`}>{item?.attributes.total_clients}</div>
            <h3>{item?.attributes.time}</h3>
        </div>
    </>)
}
export default Counters;
