import { useState, useEffect } from "react";
// import { makeRequest } from "../makeRequest";
import axios from "axios";

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async() => {
            try {
                setLoading(true);
                const res = await axios.get(process.env.REACT_APP_BASE_API + url, {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
                    }
                });
                setData(res.data.data);
                // console.log(data);
            } catch (error) {
                setError(true);
                console.log(error);
            }
            setLoading(false);
        };
        fetchData();
    }, [url]);

    return { data, error, loading };
};

export default useFetch;