import React from 'react'
import "./about.css";
const About = () => {
  return (
    <>
       <div className='details-container'>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                <div className='img-details-div'><img src="/images/img1.jpg" alt='detls_img' className='img-fluid' /> 
                
                </div>
            </div>
    
            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                <div className='details-div'>
                <h4>fghgf</h4>
                <h5>RS-/ fghgfh</h5>
               <span>fgfghfghfghfghfghfghfgh</span>
                 <div>
                      <span className='inc-dec-btn'> <i className="fa-sharp fa-solid fa-minus"></i></span>
                          <span><b>5</b></span>
                        <span className='inc-dec-btn'> <i className="fa-sharp fa-solid fa-plus"></i></span>
                    </div>
                         <button className='details-btn'>Contact Now</button>
                    
                 </div>
    
            </div>
         </div>
    
        </div>

    </>
  )
}

export default About