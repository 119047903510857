import React, { useState } from 'react';
import axios from 'axios';
import emailjs from "@emailjs/browser";
import "./changepass.css";
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/Reducers/authReducer';
import { useNavigate } from 'react-router-dom';
const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = useState(null);
  const [inputOtp, setInputOtp] = useState(null);
  const [forgotUser, setForgotUser] = useState(null);
  const [genOtp, setGenOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");

  const GenerateOtp = async (e) => {
    e.preventDefault();
    try {
    
      const verifyEmail = await axios.get(`https://next-tech-backend.onrender.com/api/customers?filters[email][$eq]=${inputEmail}`,
        {
          headers: {
            'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
          }
        });
      if (verifyEmail.data.data.length === 1) {
        var range = "0123456789";
          var otpVal = "";
          for (var i = 0; i < 6; i++) {
            otpVal += range[Math.floor(Math.random() * 10)]
          }
          setGenOtp(otpVal);
            var templateParams = {
              email: `${inputEmail}`,
              email_otp: `${otpVal}`
            };

        emailjs.send("service_4f0nila", "template_yu5e6g2", templateParams, "YNBCfU8sZwJ4SvtqD")
          .then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
          }, function (error) {
            console.log('FAILED...', error);
          });
         
        alert("OTP sent to your registered Email Id...");
        setForgotUser([verifyEmail.data.data[0]]);
        setEmailError(false);

      } else {
        setEmailError(true);
        navigate("/change-password");
      }


    } catch (error) {
      setEmailError(error.message);
    }
  }

  const VerifyOtp = (e) => {
    e.preventDefault();
    try {

      if (inputOtp) {
        if (inputOtp === genOtp) {

          setOtpError(false);
          dispatch(actions.forgotPassword(forgotUser));
          navigate("/new-password");
        } else {
          setOtpError(true);
        }

      } else {
        setOtpError(true);
        navigate("/change-password");
      }
    } catch (error) {
      setOtpError(error.message);
      navigate("/change-password");
    }
  }
  return (
    <>
      <div className='pass-main-div'>
        <div className="container-password">

          <div className="pic-wrapper">
            <img src="https://i.postimg.cc/9QpzGZk3/vector.png" className='forgot-img' alt="ntcs-forgot-img" />
          </div>

          <div className="form-wrapper">
            <form className='forgot-form' action="#" method="get">
              <div className="input-container">
                <div className="inp-wrapper">
                  <label className='forgot-label' for="email-id">Enter Email </label>
                  <input
                    type="text"
                    name="email"

                    required
                    autocomplete="off"
                    className='forgot-input'
                    onChange={(e) => setInputEmail(e.target.value)}
                    placeholder='Email-Id'
                  />
                  <div className="btn-warpper">
                    <button className='change-pass-btn' onClick={GenerateOtp}>Send OTP</button>
                    {emailError && <h5 className='text-danger mt-2'>Invalid user email..!! </h5>}
                  </div>
                  <br />

                </div>

              </div>

            </form>
            <label className='forgot-label' for="email-id">Enter 6 digits OTP Code </label>
            <input
              type="text"
              name="otp-code"

              required
              autocomplete="off"
              className='forgot-input ml-3'
              onChange={(e) => setInputOtp(e.target.value)}
              placeholder='OTP-Code'
            />
            <div className="btn-warpper">
              <button className='change-pass-btn' onClick={VerifyOtp} >Verify OTP</button>
              {otpError && <h5 className='text-danger mt-2'>Invalid OTP ..!! </h5>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;