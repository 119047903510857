import React from 'react'
import "./whatsapp.css";
import { Link } from 'react-router-dom';
const WhatsappBtn = () => {
    return (
        <>


            <Link to="https://wa.me/qr/PCAK3PTTNVP3H1" className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fa fa-whatsapp whatsapp-icon"> </i>
                {/* <span className='whatsapp-contacct'>Contact</span> */}
            </Link>

            {/* <Link to="https://chat.whatsapp.com/FBheXGjZEQNCdezazLk13u" className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fa fa-whatsapp whatsapp-icon"></i>
            </Link> */}
        </>
    )
}

export default WhatsappBtn;