import React from 'react'
import useFetch from '../../hooks/useFetch';
import "./moreser.css";
import Marquee from "react-fast-marquee";
// import axios from 'axios';
const MoreServices = () => {
    const { data, error } = useFetch('/more-services?populate=*');
   

  return (
   <>
     <div className='more-service'>
        <div className='more-service-text'>
          <h3>More Services...</h3>
        </div>

        <Marquee>
       
        {error && <h2>something Went Wrong...</h2>}
        {data ? data?.map((item) => <MarImg item={item} key={item.id} />
            ) : (
            <div />
          )}
        </Marquee>
      </div>
   </>
  )
}


const MarImg=({item})=>{
  return(<>
     <img src={item?.attributes?.img?.data?.attributes?.url} alt="alt-logo" key={item.id} />
  </>);
};


export default MoreServices;
