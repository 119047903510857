import React from 'react'
import "./accordion.css";
const Accordion = () => {
  return (
   <>

 <h4>FAQs...</h4>
<div className="accordion-wrapper">
  <div className="accordion">
    <input type="radio" name="radio-a" id="check1" checked />
    <label className="accordion-label" for="check1">Why Choose to Ntcs IT Company.?</label>
    <div className="accordion-content">
      <p>Ntcs is a software solution company in Patna,Bihar.That solves & brings your business
      online in order to reach at target customers... &#128522;</p>
    </div>
  </div>
  <div className="accordion">
    <input type="radio" name="radio-a" id="check2" />
    <label className="accordion-label" for="check2">What We Provide . ?</label>
    <div className="accordion-content">
     <ul>
      <li><p>Ntcs services provide different categories of software services in all over the world.</p></li>
      <li><p>It has services like: Mobile Apps Developement,Web Apps Development, UI/UX,Graphics Design
      Logo Design,ERP System,IT Consultancy in Patna,Bihar and so on...</p></li>
     </ul>
    </div>
  </div>
  <div className="accordion">
    <input type="radio" name="radio-a" id="check3" />
    <label className="accordion-label" for="check3">What Technologies do We use. ?</label>
    <div className="accordion-content">
     <ul>
      <li><p><b>Programming :</b> C,C++,Java,JavaScript,SQL,Nodejs.</p></li>
      <li><p><b>Frontend :</b> HTML,CSS,JAVASCRIPT,JAVA,React.JS,Redux,Nextjs
      ,TypeScript.</p></li>
      <li><p><b>Backend :</b> Java,JavaScript,PHP,Node.Js,SQL.</p></li>
      <li><p><b>Database :</b> SQL,NoSQL,mongoDb,Postgresql.</p></li>
      <li><p><b>Tools :</b> Git,Github,VsCode,ChromeDevTools,npm,Saas,Netlify,Vercel,Postman,Thunder API,
      Chrome Extensions,Boostrap.</p></li>
     </ul>
    </div>
  </div>
  <div className="accordion">
    <input type="radio" name="radio-a" id="check4" />
    <label className="accordion-label" for="check4">Does Ntcs-team Take Time To Build Software Products.?</label>
    <div className="accordion-content">
     <ul>
      <li><p>Located in Patna,Bihar ntcs-software company's members take time to build your
      software product & live onlie, After deploying end to end server.</p></li>
      <li><p>your software services time depends on your project requirements.</p></li>
     </ul>
    </div>
  </div>
  <div className="accordion">
    <input type="radio" name="radio-a" id="check5" />
    <label className="accordion-label" for="check5">is Your Software Maintained By Ntcs .?</label>
    <div className="accordion-content">
      <p>Yes ! software company aim to always maintain your software products having some
      issues in your products while onlie. </p>
    </div>
  </div>
  <div className="accordion">
    <input type="radio" name="radio-a" id="check6" />
    <label className="accordion-label" for="check6">How Much ntcs-software Service Charge .?</label>
    <div className="accordion-content">
      <p>Next Tech Consultancy Services initial charge is 30% after deploying to server charge is 100%.</p>
    </div>
  </div>
</div>



   </>
  )
}

export default Accordion;