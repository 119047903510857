import React from 'react'
import "./service.css";

import useFetch from "../../hooks/useFetch";
import { Link, useParams } from 'react-router-dom';
const Service = () => {
  const svcId = useParams().id;
  const { data, error } = useFetch(`/services/${svcId}?populate=*`);
  
    
  return (
    <>

      {/* {console.log(data)} */}

      <div className='serv-details-container mt-5'>
      { error? "Internet Disconected":(
        <div className='row'>
      
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
     
        <div className='img-details-div'><img src={data?.attributes?.img?.data?.attributes?.url}  alt='detls_img' className='img-fluid' />

        </div>
      </div>

      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
        <div className='details-div'>
          <h4>{data?.attributes?.title}</h4>
          {/* <h5>{data?.attributes?.sub_title}</h5> */}
          <img src={data?.attributes?.img2?.data?.attributes?.url}  alt='ntcs-service-img' className='img-fluid ' />
          <span className='mt-2'>{data?.attributes?.sub_desc}</span>
          <div>
           
          </div>
         <Link to="/requirement"><button className='details-btn mt-2'>Book Now</button></Link> 

        </div>

      </div>
    </div>
      )}
       

      </div>
    </>
  )
}

export default Service