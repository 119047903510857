import {  createSlice } from "@reduxjs/toolkit";
const ntcsSlice = createSlice({
    name: "ntcs",
    initialState: {
        currentUser: [],
        forgotUser: [],
        favourite: []

    },
    reducers: {
        loginUser: (state, action) => {
            state.currentUser = action.payload;
        },
        logoutUser: (state) => {
            state.currentUser = [];
        },
        forgotPassword: (state, action) => {
            state.forgotUser = action.payload;
        },
        newPassword: (state) => {
            state.forgotUser = [];
        },
        addFavourite: (state, action) => {
            state.favourite.push(action.payload);
        },
        clearToFav: (state) => {
            state.favourite = [];
        }

    },
});
export const actions = ntcsSlice.actions;
// const store = configureStore({ reducer: ntcsSlice.reducer, });
export default ntcsSlice.reducer;