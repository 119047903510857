import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate();
    const [err, setErr] = useState();
    const [regData, setRegData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',

    });


    const handleChange = (e) => {
        setRegData({ ...regData, [e.target.name]: e.target.value });
    }

    console.log(regData);
    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const regUser = await axios.post("https://next-tech-backend.onrender.com/api/customers",
                { "data": regData },
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
                    }
                }
            );
            console.log(regUser);
            navigate("/login");

        } catch (error) {
            setErr(error.message);
            navigate("/register");

        }

    }
    return (
        <>

            <div className="login-div">
                <div className="loginContainer">
                    <h3>Register Now !</h3>
                    <input
                        type="text"
                        placeholder="username"
                        id="name"
                        onChange={handleChange}
                        className="loginInput"
                        name="name"
                    />
                    <input
                        type="email"
                        placeholder="email"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        className="loginInput"
                    /><input
                        type="text"
                        placeholder="Mobile"
                        id="mobile"
                        name="phone"
                        onChange={handleChange}
                        className="loginInput"
                    />
                    <input
                        type="password"
                        placeholder="password"
                        id="password"
                        name="password"
                        onChange={handleChange}
                        className="loginInput"
                    />
                    <button
                        //  disabled={loading} 
                        onClick={handleClick}
                        className="loginButton">
                        Register
                    </button>
                    {err && <span style={{ color: "red" }}>{err}</span>}
                    {/* {loginErr ? <span style={{ color: "red" }}>Wrong Credentials !!...</span> : <span></span>} */}
                    <div>
                        <br></br>
                        <hr />
                        <h5> have an account ?.</h5>
                        <br />
                        <Link to="/login">SignIn here !.</Link>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Register