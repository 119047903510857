
import Slider from "../Slider/Slider";
import CompanyAbout from "../CompanyAbout/CompanyAbout";
import Services from "../Services/Services";
import Counters from "../Counters/Counters";
import Clients from "../OurClients/Clients";
import OurTeam from "../OurComm/OurTeam";
import Contact from "../ContactUs/Contact";
import Feedback from "../Feedback/Feedback";
import Accordion from "../Accordion/Accordion";

const Home = () => {
 
  return (
    <>
      <Slider />
      <CompanyAbout />
      <Services />
      <Counters />
      <OurTeam />
      <Clients />
      <Feedback />
      <Contact />
      <Accordion />
    </>
  );
};

export default Home;
