import React from 'react'
import useFetch from "../../hooks/useFetch";
import { Link, useParams } from 'react-router-dom';

const TeamSingle = () => {

    const teamId = useParams().id;
    const { data, error} = useFetch(`/teams/${teamId}?populate=*`);
   
    return (
        <>
            {/* {console.log(data)} */}
            <div className='details-container'>
                {error ? "Something Went Wrong..." : (
                    <div className='row'>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>

                            <div className='img-details-div'><img src={data?.attributes?.img2?.data?.attributes?.url} alt='detls_img' className='img-fluid' />

                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                            <div className='details-div'>
                                <h4>{data?.attributes?.name}</h4>
                                <h4>{data?.attributes?.profession}</h4>
                                <h5>{data?.attributes?.edu1},{data?.attributes?.edu2}</h5>
                               
                                <span>{data?.attributes?.bio}</span>
                                <div>
                                    <span className='inc-dec-btn'> <i className="fa-sharp fa-solid fa-minus"></i></span>
                                    {/* <span><b>5</b></span> */}
                                    <span className='inc-dec-btn'> <i className="fa-sharp fa-solid fa-plus"></i></span>
                                </div>
                                <Link to="/"><button className='details-btn'>Contact Now</button></Link>

                            </div>

                        </div>
                    </div>
                )}


            </div>
        </>
    )
}

export default TeamSingle;