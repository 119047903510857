import React from 'react'
import "./client.css";
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import axios from 'axios';
import useFetch from '../../hooks/useFetch';


const Clients = () => {

  


    const { data, error } = useFetch('/recent-clients?populate=*');
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    return (
        <>

            <div className="communicate">
                <h1>Your Vision,Your Software </h1>
                <h3>We Just Built It For Your High Business Efficiency.</h3>
                <a href="#contact"><button className="btn btn-info text-white">Contact Now</button></a>
            </div>
            <br />
            <div className='client-header'>
            <h2>Our Recent Clients</h2>
            <div>

            </div>
        </div>
        <Carousel responsive={responsive}
                //  showDots={true}
                 infinite={true}
                 autoPlaySpeed={5000}
                 removeArrowOnDeviceType={["tablet", "mobile"]}
                 infiniteLoop autoPlay
                 className="clients" >
                    {/* {loading && <Loader />} */}
                   {
                    error?<h2>something Went Wrong...</h2>:data ? data?.map((item) => <ClientCard item={item} key={item.id} />
                    )
                    :<div/>}
         
                {/* <div className="row client-card" >
                    <div className="card ">
                        <div className="image">
                            <img src="/images/pexels-andrea-piacquadio-845434.jpg" alt="Team member 1" />
                        </div>
                        <div className="info">
                            <h3>Schott Watkins</h3>
                            <span>Web Developer</span>
                            <div className="icons">
                                <Link to="/" className="fab fa-facebook-f"></Link>
                                <Link to="/" className="fab fa-twitter"></Link>
                                <Link to="/" className="fab fa-instagram"></Link>
                                <Link to="/" className="fab fa-linkedin"></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row client-card" >
                    <div className="card ">
                        <div className="image">
                            <img src="/images/pexels-andrea-piacquadio-845434.jpg" alt="Team member 1" />
                        </div>
                        <div className="info">
                            <h3>Schott Watkins</h3>
                            <span>Web Developer</span>
                            <div className="icons">
                                <Link to="/" className="fab fa-facebook-f"></Link>
                                <Link to="/" className="fab fa-twitter"></Link>
                                <Link to="/" className="fab fa-instagram"></Link>
                                <Link to="/" className="fab fa-linkedin"></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row client-card" >
                    <div className="card ">
                        <div className="image">
                            <img src="/images/pexels-andrea-piacquadio-845434.jpg" alt="Team member 1" />
                        </div>
                        <div className="info">
                            <h3>Schott Watkins</h3>
                            <span>Web Developer</span>
                            <div className="icons">
                                <Link to="/" className="fab fa-facebook-f"></Link>
                                <Link to="/" className="fab fa-twitter"></Link>
                                <Link to="/" className="fab fa-instagram"></Link>
                                <Link to="/" className="fab fa-linkedin"></Link>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Carousel>;

            <br /><br /><br />
            <br /><br /><br />


        </>
    )
}


const ClientCard = ({ item }) => {
    return (<>
       <div className="row client-card" >
                    <div className="card ">
                        <div className="image">
                        <img src={item?.attributes?.img?.data?.attributes?.url}  alt="Team member 1" />
                        </div>
                        <div className="info">
                            <h2>{item?.attributes?.name}</h2>
                            <h4>{item?.attributes?.title}</h4>
                           <Link to={`${item?.attributes?.website_url}`} target='_blank' className="app-text">My Application</Link>
                        
                        </div>
                    </div>
                </div>
    </>)
}

export default Clients