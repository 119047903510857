import React from 'react'
import useFetch from "../../hooks/useFetch";
import { Link, useParams } from 'react-router-dom';
const FounderSingle = () => {
    const founderId = useParams().id;
    const { data, error} = useFetch(`/founders/${founderId}?populate=*`);
  return (
   <>
     <div className='details-containers'>
                {error ? "Something Went Wrong..." : (
                    <div className='row'>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-5 '>

                            <div className='img-details-div text-center' ><img src={data?.attributes?.img2?.data?.attributes?.url} alt='ntcs-founder-img' style={{maxWidth:"40%",marginBottom:"10%"}} className='img-fluid ' />

                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4 '>
                            <div className='details-div  p-5  ' >
                                <h4>{data?.attributes?.name}</h4>
                                <h3>{data?.attributes?.profession}</h3>
                                <h5>{data?.attributes?.edu1},{data?.attributes?.edu2}</h5>
                               
                                <p>{data?.attributes?.bio}</p>
                                <Link to="/"><button className='details-btn'>Contact Now</button></Link>

                            </div>

                        </div>
                    </div>
                )}


            </div>
        </>

  
  )
}

export default FounderSingle