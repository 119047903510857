import React from 'react'
import "./footer.css";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
   

    <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-map-marker-alt"></i>
                           
                            <div className="cta-text">
                                <h4>Find us</h4>
                                <span>Bihar,Muzaffarpur-842003</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-phone-square"></i>
                            <div className="cta-text">
                                <h4>Call us</h4>
                                <span>6205988966</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-envelope"></i>
                            <div className="cta-text">
                                <h4>Mail us</h4>
                                <span>manishkumarpatna2020@info.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    {/* <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <Link href="index.html"><img src="/images/ntcs.jpg" className="img-fluid" alt="logo" /></Link>
                            </div>
                            <div className="footer-text">
                                <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                                elit,Lorem ipsum dolor sit amet.</p>
                            </div>
                            <div className="footer-social-icon">
                                <span>Follow us</span>
                                <Link href="#"><i className="fab fa-facebook-f facebook-bg"></i></Link>
                                <Link href="#"><i className="fab fa-twitter twitter-bg"></i></Link>
                                <Link href="#"><i className="fab fa-google-plus-g google-bg"></i></Link>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/">about</Link></li>
                                <li><Link to="/">services</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                                <li><Link to="/service">Our Services</Link></li>
                                <li><Link to="/team">Expert Team</Link></li>
                               
                               
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Subscribe Telegram</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div className="subscribe-form">
                               <Link to="">  <input type="button" className='h5 p-4 bg-danger' value="Visit To Telegram"/>
                                    <button className='pb-3'><i className="fab fa-telegram-plane "></i></button></Link>
                                    {/* <input type="text" value="tele.com/link"placeholder="Join Telegram" /> */}
                                  
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div className="copyright-text">
                            {/* <p>Copyright &copy; 2022, All Right Reserved <Link href="https://codepen.io/Linknupkumar92/">Er.Manish Gupta</Link></p> */}
                            <p>Developed By <Link to="https://er-manishgupta.vercel.app" target='_blank'>Er. Manish Gupta</Link> , MITian</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div className="footer-menu">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/">Terms</Link></li>
                                <li><Link to="/">Privacy</Link></li>
                                <li><Link to="/">Policy</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer