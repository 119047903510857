import React from 'react'
import "./companydtl.css";
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useFetch from '../../hooks/useFetch';
// import ImageSlider, { Slide } from "react-auto-image-slider";
const CompanyDetails = () => {
  const { data, error} = useFetch(`/founders?populate=*`);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
  return (
    <>
      <div className='details-container'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 left-div mt-5 ntcs-col-div'>
            {/* <div className='img-details-div'>

             <ImageSlider effectDelay={1000} autoPlayDelay={1000} className="img-slide">
              <Slide>
                <img alt="img2" src="/images/img1.jpg" />
              </Slide>
              <Slide>
                <img alt="img2"  src="/images/img1.jpg" />
              </Slide>
              <Slide>
                <img alt="img1" src="/images/img1.jpg" />
              </Slide>
            </ImageSlider>
       

            </div> */}
            <div className='ntcs-text-div'>
              <h3 className="animate-charcter">N<span style={{ fontSize: "100px" }}>tcs</span></h3>
              <h1>Next Tech Consultancy Services</h1>
            </div>

          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
            <div className='details-div'>
              <h4 className='text-center'>About Us</h4>
              <p>Next Tech IT Solutions Pvt.Ltd. Founded in 2023
                with a goal to change the bussiness strategy
                and provides IT solutions for Bussiness/StartUps/Organisations
                and helps them to <b>turn bussiness online </b><br />
                and to cover large area of the markets ,also made easy for them
                <b>markenting and management work </b>
              </p>
              <h5>Mission :</h5>
              <p>“Develop and deliver unmatched quality software products, 
              Solutions & services that offer our customers excellent ROI
               (Return on Investment) and productivity”.</p>
              <h5>Vission :</h5>
              <p>To become the most sought after multi service provider by way of consistency in delivering quality and value multiplying services within the constraint of
               time and capital under the arc of compliance to regulations concerned.</p>
              <h5>Values :</h5>
           <p>Teamwork
              Trustworthiness
              * Transparency
              * Empowerment
              *Quality
              *Passion for excellence</p>

            </div>
          </div>
        </div>

      </div>
      <br/>
      <div className=' align-center'>

                <Carousel responsive={responsive}
                  showDots={true}
                  infinite={true}
                  autoPlaySpeed={10000}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  infiniteLoop autoPlay
                  className="clients" >
                  {error ? <h2>something Went Wrong...</h2>: data ? data.map((item) => <FounderCard item={item} key={item.id} />
                    )
                    :<div/>}
                </Carousel>;
</div>
    </>
  )
}

const FounderCard=({item})=>{
  return(<>
     <div className="row founder-row pt-2" >
                    <div className="card-founder ">
                      <div className="image-founder">
                        <img src={item?.attributes?.img?.data?.attributes?.url} alt="ntcs-team member " />
                      </div>
                      <div className="info-founder">
                        <h3>{item?.attributes?.name}</h3>
                        <h5>{item?.attributes?.profession}</h5>
                        <span>{item?.attributes?.facebook}</span>
                        <div className="icons-founder pl-2">
                          <Link to={`${item?.attributes?.insta}`} className="fab fa-instagram m-2"></Link>
                          <Link to={`${item?.attributes?.linkedin}`} className="fab fa-linkedin m-2"></Link>
                        </div>
                      </div>
                      <Link to={`/founder/${item?.id}`}><button className='details-btn'>More Info...</button></Link>
                     
                    </div>
                  </div>
  </>)
}

export default CompanyDetails