import React, { useState } from 'react'
import "./login.css";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/Reducers/authReducer';
const Login = () => {
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const [err, setErr] = useState();
    const [loginErr, setLoginErr] = useState();
    const [loginData, setRegData] = useState({
        login_email: '',
        login_pass: ''




    });



    const handleChange = (e) => {
        setRegData({ ...loginData, [e.target.name]: e.target.value });
    }

    const handleClick = async () => {
        try {
            const verifyLogin = await axios.get(`https://next-tech-backend.onrender.com/api/customers?filters[email][$eq]=${loginData.login_email}&filters[password][$eq]=${loginData.login_pass}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
                    }
                });

            if (verifyLogin.data.data.length === 1) {

                // console.log(verifyLogin);

                // localStorage.setItem("userData", JSON.stringify(verifyLogin.data.data[0]));
                dispatch(actions.loginUser([verifyLogin.data.data[0]]));
                alert("login Successful..");
                // console.log(verifyLogin.data.data[0].id);
                navigate("/");
            } else {
                setLoginErr(true);
                navigate("/login")
            }
            // console.log(verifyLogin);

        } catch (error) {
            setErr(error.message);
            console.log(error.message);
            navigate("/login");

        }

    }
    return (
        <>
            <div className="login-div">

                <div className="loginContainer">
                    <h3>Login Pannel</h3>
                    <input
                        type="email"
                        placeholder="username"
                        id="email"
                        name="login_email"
                        onChange={handleChange}
                        className="loginInput"
                    />
                    <input
                        type="password"
                        placeholder="password"
                        id="password"
                        name="login_pass"
                        onChange={handleChange}
                        className="loginInput"
                    />
                    <button
                        //  disabled={loading} 
                        onClick={handleClick}
                        className="loginButton">
                        Login
                    </button>
                    {err && <span>{err.message}</span>}
                    {loginErr ? <span style={{ color: "red" }}>Wrong Credentials !!...</span> : <span></span>}
                    <div>
                    <Link to="/change-password">Forgot Password.?</Link>
                        <br></br>
                        <hr />
                        <h5>Don't have an account ?.</h5>
                        <br />
                        <Link to="/register">Register here !.</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login