import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import WhatsappBtn from "./WhatsappChat/WhatsappBtn";
import Loader from "../components/Loader/Loader";
import useFetch from "../hooks/useFetch";
const Layout = () => {
  const {  loading } = useFetch('/founders?populate=*');

  return (
    <>
      <div>
        {loading ? <Loader /> : (
          <>
            <Header />
            <Outlet />
            <Footer />
            <WhatsappBtn />
          </>
        )}




      </div>
    </>
  );
};

export default Layout;
